// define a mixin object
const contentDisposition = require('content-disposition');

import {log} from "@/shared/utils";
import {MEDIA_ROOT} from "@/shared/consts";
import {downloadPublishFormat} from "@/shared/dataservice";

export const advertExportActionsMixin = {
    data() {
        return {

        }
    },
    computed: {
        mediaBasePath() {
            return MEDIA_ROOT;
        },
        previewFormats() {
            return this.publishFormats.filter(row => { return row.hasPreview });
        }
    },
    methods: {
        buildPreviewImagePath(advertID, publishedFormatKey = ""){
            return `${this.mediaBasePath}/publishedformat/${advertID}/Thumbnail/${publishedFormatKey}`;
        },
        onDownloadPublishFormat(e, advertID ,publishedFormatKey){
            e.preventDefault();
            this.triggerDownloadPublishFormat(advertID, publishedFormatKey);
        },
        async triggerDownloadPublishFormat(advertID ,publishedFormatKey){
            await downloadPublishFormat(advertID ,publishedFormatKey)
                .then(response => {
                    log(["Response", response]);
                    return response;
                })
                .then(response => {
                    let objectUrl = window.URL.createObjectURL(response.data);
                    let disposition = contentDisposition.parse(response.headers["content-disposition"]);

                    log(["ContentDisposition", response, response.headers["content-disposition"], disposition, disposition.parameters.filename]);

                    const anchor = document.createElement('a');

                    anchor.setAttribute('id', 'deleteMe');
                    anchor.href = objectUrl;
                    anchor.setAttribute('download', disposition.parameters.filename);
                    document.body.appendChild(anchor);
                    anchor.click();
                    anchor.remove();
                });
        },
    },
}